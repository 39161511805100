export const viewMixin = {
  data() {
    return {
      shareLink: '',
    };
  },
  emits: ['sendinterest', 'updateShortlist', 'blockUser'],
  methods: {
    photoPresence(profile) {
      return (
        profile.data.attributes.profile_photo_thumb_url ||
        profile.data.attributes.user_profile_photo_thumb_url
      );
    },
    displayPhoto(profile) {
      return profile.data.type === 'dashboard_user'
        ? profile.data.attributes.profile_photo_thumb_url
        : profile.data.attributes.user_profile_photo_thumb_url;
    },
    confirmBlockUser(id) {
      this.blockDialog = true;
      this.selectedUserId = id;
    },
    blockUser() {
      this.$emit('blockUser', this.selectedUserId, this.block);
      this.blockDialog = false;
    },
    updateList(id, shortlisted) {
      this.$emit('updateShortlist', id, shortlisted);
    },
    sendinterest(id) {
      this.$emit('sendinterest', id);
    },
    blockMsg(profile) {
      if (!this.blockPage || !profile.data.attributes.is_active) {
        this.redirect(profile.data);
      } else {
        this.$moshaToast('Unblock to view profile', {
          type: 'info',
          showIcon: true,
        });
      }
    },
    redirect(data) {
      if (data.attributes.user_is_active || data.attributes.is_active) {
        data.type === 'dashboard_user'
          ? this.$router.push(`/profile/${data.attributes.slug}`)
          : this.$router.push(`/profile/${data.attributes.user_slug}`);
      } else {
        this.$moshaToast('This profile is deleted', {
          type: 'info',
          showIcon: true,
          timeout: 2500,
        });
      }
    },
    shareProfile(id) {
      this.shareLink = '';
      let that = this;
      this.$http
        .post('/users/share_profile', { target_id: id })
        .then((res) => {
          that.shareLink = res.data.link;
        })
        .catch(() => {
          that.shareLink = 'error';
          that.$refs.share_id.hide();
          that.$moshaToast('Something went wrong', {
            type: 'danger',
            showIcon: true,
            timeout: 2000,
          });
        });
    },
    toggle(event, id) {
      this.$refs.share_id.toggle(event);
      this.shareProfile(id);
    },
    copyToClipboard() {
      const clipboardData = window.clipboardData || navigator.clipboard;
      clipboardData.writeText(this.shareLink);
      this.$refs.share_id.hide();
      this.$moshaToast('Copied to clipboard', {
        type: 'success',
        showIcon: true,
        timeout: 2000,
      });
    },
  },
};
