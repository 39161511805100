<template>
  <div>
    <DataView :value="loadingArray" layout="grid">
      <template #grid="">
        <div class="p-col-12 p-sm-6 p-lg-4 p-xl-3">
          <div class="card p-mb-0 p-mx-2 p-mt-3 p-p-0">
            <div class="p-grid">
              <div class="p-col-6">
                <div class="profile">
                  <Skeleton height="120px" width="100%" border-radius="16px" />
                </div>
              </div>
              <div class="p-col-6 p-d-flex p-flex-column p-jc-between">
                <Skeleton
                  v-for="width in widths"
                  :key="width"
                  height="1rem"
                  :width="width"
                  class="p-mb-1"
                  border-radius="16px"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </DataView>
  </div>
</template>

<script>
export default {
  props: {
    profileNumber: {
      type: Number,
      default() {
        return 10;
      },
    },
  },
  data() {
    return {
      loadingArray: [],
      widths: ['80%', '40%', '50%', '60%', '40%', '70%'],
    };
  },
  mounted() {
    for (var i = 0; i < this.profileNumber; i++) {
      this.loadingArray.push({});
    }
  },
};
</script>
