import { mapGetters } from 'vuex';

export const mailboxMixin = {
  data() {
    return {
      layout: 'grid',
      selectedLayout: 'grid',
      selectedContact: null,
      showNumber: false,
      windowWidth: false,
      profiles: [],
      loading: false,
      loadProfiles: false,
      profileNumber: 10,
      page_no: 1,
      eof: false,
      query: {},
    };
  },
  created() {
    window.onbeforeunload = function () {
      window.scrollTo(0, 0);
    };
  },
  beforeMount() {
    this.loading = true;
    this.getInitialProfiles();
  },
  mounted() {
    this.calcWinWidth();
    window.addEventListener('resize', this.resizing);
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.calcWinWidth);
  },
  unmounted() {
    window.removeEventListener('resize', this.calcWinWidth);
    window.removeEventListener('resize', this.resizing);
    window.removeEventListener('scroll', this.handleScroll);
  },
  computed: {
    ...mapGetters(['gender']),
  },
  methods: {
    calcWinWidth() {
      this.windowWidth = window.innerWidth > this.$tabletView;
    },
    toggleView(view) {
      this.selectedLayout = view;
      this.resizing();
    },
    resizing() {
      if (window.innerWidth >= 1200) {
        this.adButton = true;
      } else {
        this.adButton = false;
      }
      if (window.innerWidth <= 535) {
        this.layout = 'grid';
      } else {
        this.layout = this.selectedLayout;
      }
    },
    copyToClipboard(msg) {
      const clipboardData = window.clipboardData || navigator.clipboard;
      clipboardData.writeText(msg);
      this.$moshaToast('Copied to clipboard', {
        type: 'success',
        showIcon: true,
        timeout: 2000,
      });
      this.clipBoard = false;
    },
    showMobileNumber(id) {
      this.showNumber = true;
      this.selectedContact = id;
    },
    hideMobleNumber() {
      this.showNumber = false;
      this.selectedContact = null;
    },
    removeFromMails(id) {
      let index = this.profiles.indexOf(
        this.profiles.find((profile) => profile.id === id.toString())
      );
      this.profiles.splice(index, 1);
    },
    blockUser(id, block) {
      let that = this;
      this.$http
        .delete(`interests/${id}?block_user=${block ? 'true' : 'false'}`)
        .then(() => {
          that.removeFromMails(id);
          that.$moshaToast(
            `Successfully ${
              block
                ? 'removed the invitation and blocked the user'
                : 'removed the invitation'
            }  .`,
            {
              type: 'success',
              showIcon: true,
              timeout: 3000,
            }
          );
        })
        .catch(() => {
          that.$moshaToast('Something went wrong, please try again.', {
            type: 'danger',
            showIcon: true,
            timeout: 2000,
          });
        });
    },
    showErr(msg = null) {
      this.ErrorMsg = msg;
      this.errStatus = true;
      setTimeout(() => {
        this.errStatus = false;
        this.ErrorMsg = null;
      }, 2000);
    },

    // pagination methods
    async handleScroll() {
      let bottomOfWindow =
        window.innerHeight + window.scrollY + 1 >= document.body.offsetHeight;
      if (bottomOfWindow && !this.eof && !this.loading) {
        if (!this.loadProfiles) {
          this.loadProfiles = true;
          let load = this.fetchProfiles();
          load
            .then(() => {
              this.loadProfiles = false;
            })
            .catch(() => {
              this.loadProfiles = false;
            });
        }
      }
    },
    async getInitialProfiles() {
      const heightOffset = 170;
      let column = 4;
      let row = 1;
      let cardHeight = 130;
      if (window.innerWidth < 576) {
        column = 1;
      } else if (window.innerWidth < 992) {
        column = 2;
      } else if (window.innerWidth < 1200) {
        column = 3;
      }
      while (row * cardHeight + heightOffset < window.innerHeight) {
        row += 1;
      }
      this.profileNumber = Math.ceil((column * row) / 10) * 10;
      let load = this.fetchProfiles();
      load.then(() => {
        this.loadProfiles = false;
      });
    },
    async fetchProfiles(filter) {
      let that = this;
      await this.$http
        .get(that.reqUrl, {
          params: {
            page_no: that.page_no,
            size: this.profileNumber,
          },
        })
        .then((res) => {
          if (filter) {
            that.profiles = res.data.data;
          } else if (res.data.data.length > 0) {
            that.profiles = that.profiles.concat(res.data.data);
          }
          that.page_no += 1;
          res.data.data.length < that.profileNumber ? (that.eof = true) : null;
          that.loading = false;
        })
        .catch(() => {
          that.$moshaToast('Something went wrong.', {
            type: 'danger',
            duration: 2000,
          });
          that.loading = false;
        });
    },
  },
};
