import { mapGetters } from 'vuex';
export const profileApprovalMixin = {
  computed: {
    ...mapGetters(['authUser', 'canApproveProfile']),
    isRejected() {
      return !this.profile.is_approved && this.profile.comment != null;
    },
    canShowApprovalButtons() {
      return !this.profile.is_approved && this.canApproveProfile;
    },
  },
  methods: {
    approvalFor(data) {
      let msg = 'NA';
      if (data['has_unapporved_photos?'] && !data.is_approved) {
        msg = 'Profile/Photo';
      } else if (data['has_unapporved_photos?'] && data.is_approved) {
        msg = 'Photo';
      } else if (!data['has_unapporved_photos?'] && !data.is_approved) {
        msg = 'Profile';
      }
      return 'Approval for: ' + msg;
    },
  },
};
