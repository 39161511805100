<template>
  <div class="p-grid list-width">
    <DataView :value="profiles" layout="list">
      <template #list="profile">
        <div class="p-col-12 list-container p-d-flex">
          <div class="photo-container">
            <div class="list-item">
              <div class="item-itself">
                <div
                  :class="`${
                    photoPresence(profile) ? '' : 'img-border'
                  } profile-list p-p-0`"
                >
                  <img
                    :src="`
                        ${
                          !photoPresence(profile)
                            ? profile.data.attributes.gender === 'female' ||
                              profile.data.attributes.user_gender === 'female'
                              ? '/bride.png'
                              : '/groom.png'
                            : displayPhoto(profile)
                        }
                      `"
                    class="profile__img__list"
                    @click="blockMsg(profile)"
                  />
                  <img
                    :src="displayPhoto(profile)"
                    :class="photoPresence(profile) ? 'profile_bg_img_list' : ''"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="name-age-mt list-item-container">
            <div class="list-item">
              <div class="item-itself">
                <h5
                  v-if="profile.data.type === 'dashboard_user'"
                  class="c-tc-pd pointer"
                  @click="blockMsg(profile)"
                >
                  {{ profile.data.attributes.first_name || 'NA' }}
                </h5>
                <h5
                  v-else
                  class="c-tc-pd pointer"
                  @click="redirect(profile.data)"
                >
                  {{ profile.data.attributes.user_name || 'NA' }}
                </h5>
              </div>
            </div>

            <div class="list-item">
              <div
                v-if="profile.data.type === 'dashboard_user'"
                class="item-itself c-tc-p-cfor"
              >
                {{
                  ageHeight(
                    profile.data.attributes.age,
                    profile.data.attributes.height
                  )
                }}
              </div>
              <div v-else class="item-itself c-tc-p-cfor">
                {{
                  ageHeight(
                    profile.data.attributes.user_age,
                    profile.data.attributes.user_height
                  )
                }}
              </div>
            </div>

            <div class="list-item">
              <div
                v-if="profile.data.type === 'dashboard_user'"
                class="item-itself c-tc-p-cfor"
              >
                {{ profile.data.attributes.mother_tongue || 'NA' }}
              </div>
              <div v-else class="item-itself c-tc-p-cfor">
                {{ profile.data.attributes.user_mother_tongue || 'NA' }}
              </div>
            </div>
          </div>

          <div class="address-profession list-item-container">
            <div class="list-item">
              <div
                v-if="profile.data.type === 'dashboard_user'"
                class="item-itself c-tc-p-cfor"
              >
                {{
                  profile.data.attributes.address_state +
                  ', ' +
                  profile.data.attributes.address_country
                }}
              </div>
              <div v-else class="item-itself c-tc-p-cfor">
                {{ profile.data.attributes.user_state }},
                {{ profile.data.attributes.user_country }}
              </div>
            </div>
            <div class="list-item">
              <div
                v-if="profile.data.type === 'dashboard_user'"
                class="item-itself c-tc-p-cfor"
              >
                {{ job(profile.data.attributes.job_type) }}
              </div>
              <div v-else class="item-itself c-tc-p-cfor">
                {{ job(profile.data.attributes.user_job) }}
              </div>
            </div>
          </div>
          <div
            v-if="
              !authUser.is_admin &&
              !blockPage &&
              profile.data.type === 'dashboard_user'
            "
            class="interactives list-item-container"
          >
            <div class="list-item">
              <div class="item-itself">
                <i
                  v-tooltip.top="'Shortlist'"
                  :class="`${
                    profile.data.attributes['shortlisted?']
                      ? 'fas fa-heart'
                      : 'far fa-heart'
                  } heart-icon`"
                  @click="
                    updateList(
                      profile.data.attributes.id,
                      profile.data.attributes['shortlisted?']
                    ),
                      (profile.data.attributes['shortlisted?'] =
                        !profile.data.attributes['shortlisted?'])
                  "
                ></i>
                <i
                  v-if="profile.data.attributes.is_active"
                  v-tooltip.top="'Share'"
                  class="pi pi-share-alt share-icon"
                  @click="toggle($event, profile.data.attributes.id)"
                ></i>
                <OverlayPanel ref="share_id" class="ss-lv-sm-op">
                  <div v-if="shareLink && shareLink !== 'error'">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      <div class="overlay-text">
                        <p class="p-text-no p-text-truncate share-link">
                          {{ shareLink }}
                        </p>
                      </div>
                      <div>
                        <i
                          class="copy-btn fas fa-copy"
                          @click="copyToClipboard"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-else-if="shareLink === ''">
                    <div class="p-d-flex p-jc-between p-ai-center">
                      <dot-loader class="dot-loader p-my-3" theme="dark" />
                    </div>
                  </div>
                </OverlayPanel>
              </div>
            </div>
            <div class="list-item">
              <div class="item-itself">
                <div
                  v-if="!profile.data.attributes.is_active"
                  class="
                    p-d-flex p-ai-center p-text-center p-pr-2
                    deleted-profile
                  "
                >
                  <i class="pi pi-trash p-mr-2"></i>
                  <p>Profile deleted</p>
                </div>
                <div
                  v-else-if="profile.data.attributes['sent_interest?']"
                  class="
                    p-d-flex p-ai-center p-text-center p-pr-2
                    interest-sent
                  "
                >
                  <i class="pi pi-check-circle p-mr-2"></i>
                  <p>interest sent</p>
                </div>
                <div
                  v-else-if="profile.data.attributes['received_interest?']"
                  class="p-d-flex p-ai-center p-text-center p-pr-2"
                >
                  <i class="pi pi-check-circle p-mr-1"></i>
                  <p>interest received</p>
                </div>
                <div v-else class="p-d-flex p-ai-center">
                  <Button
                    label="Send interest"
                    class="p-button-sm"
                    @click="
                      sendinterest(profile.data.attributes.id),
                        (profile.data.attributes['sent_interest?'] = true)
                    "
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            v-else-if="!authUser.is_admin"
            class="interactives list-item-container"
          >
            <slot
              :name="`${
                profile.data.attributes.user_is_active ||
                profile.data.attributes.is_active
                  ? 'activeProfile'
                  : 'deletedProfile'
              }`"
              :profile="profile"
            />
          </div>
          <!-- undo/block button -->
          <div
            v-show="profile.data.type === 'sent_interest'"
            class="close-button-list p-mr-1 p-mt-1"
          >
            <i
              v-tooltip.left="'Undo the sent interest?'"
              class="pi pi-times close-button"
              @click="confirmBlockUser(profile.data.id)"
            ></i>
          </div>
        </div>
      </template>
    </DataView>
    <div v-show="loadProfiles" class="loading-profiles">
      <div class="message">
        <div class="loadingio-spinner-dual-ring-5twzi63sc83">
          <div class="ldio-qt7gh4kx5nf">
            <div></div>
            <div><div></div></div>
          </div>
        </div>
        <p>Loading more profiles for you...</p>
      </div>
    </div>
  </div>
  <Dialog
    v-model:visible="blockDialog"
    :modal="true"
    :draggable="false"
    :closable="true"
    :close-on-escape="true"
    header="Confirmation"
    :dismissable-mask="true"
    class="block-dialog"
  >
    <div class="p-d-flex p-flex-column block-user-dialog">
      <p>Do you want to undo the sent interest?</p>
      <div class="p-d-flex p-ai-center">
        <Checkbox v-model="block" :binary="true" />
        <label for="block_user_check" class="p-ml-2">Block as well</label>
      </div>
    </div>
    <template #footer>
      <Button
        label="Yes"
        class="p-mr-5 p-button-outlined"
        @click="blockUser()"
      /><Button label="No" autofocus @click="blockDialog = false" />
    </template>
  </Dialog>
</template>

<script>
import DotLoader from '@/utilities/DotLoader.vue';
import '@/assets/ListAndGridView.scss';
import { viewMixin } from '@/mixins/gridAndListViewMixin.js';
import { ageHeightMixin } from '@/mixins/ageHeightMixin.js';
import { profileApprovalMixin } from '@/mixins/profileApprovalMixin.js';

export default {
  components: { DotLoader },
  mixins: [viewMixin, ageHeightMixin, profileApprovalMixin],
  props: {
    profiles: {
      type: Array,
      default() {
        return [];
      },
    },
    profileNumber: {
      type: Number,
      default() {
        return 10;
      },
    },
    loading: { type: Boolean, default: true },
    loadProfiles: Boolean,
    showPendingApproval: { type: Boolean, default: false },
    blockPage: { type: Boolean, default: false },
  },
  data() {
    return {
      blockDialog: false,
      selectedUserId: '',
      block: false,
    };
  },
  methods: {
    job(job) {
      return job ? job.replace(/_/g, ' ') : 'NA';
    },
  },
};
</script>

<style scoped lang="scss">
.list-container {
  .photo-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .interactives {
    max-width: 250px;
    .heart-icon {
      margin-right: 50%;
    }
  }
  .list-item-container {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 10px;
    .list-item {
      display: flex;
      justify-content: center;
      width: 100%;
      padding-left: 10px;
      padding-right: 10px;
      .item-itself {
        width: 100%;
        display: flex;
        justify-content: center;
        text-align: center;
      }
    }
    &.interactives {
      .list-item {
        max-width: 100%;
        .item-itself {
          width: unset;
          max-width: 100%;
        }
      }
    }
  }
  h5 {
    margin: 0px 0px 0px 0px;
  }
  @media screen and (max-width: $tabletView) {
    .list-item-container {
      flex-direction: column;
      .list-item {
        .item-itself {
          text-align: start;
        }
      }
      &.name-age-mt {
        align-items: flex-start;
        .list-item {
          .item-itself {
            justify-content: start;
          }
        }
      }
      &.address-profession {
        align-items: flex-start;
        .list-item {
          .item-itself {
            justify-content: start;
          }
        }
      }
    }
  }
  background: $backgroundColor;
  border: 1px solid $borderColor !important;
  border-radius: 10px;
  width: 100%;
  justify-content: space-around;
  margin-bottom: 10px;
  position: relative;
  .close-button {
    font-size: 9px;
    color: $primaryColor;
    font-weight: 600;
    padding: 3px;
    border: 1px solid $primaryColor;
    border-radius: 50%;
    cursor: pointer;
  }
  .close-button-list {
    z-index: 1500;
    display: flex;
    align-items: start;
    position: absolute;
    right: 0;
    top: 0;
  }
}
.block-user-dialog {
  width: 35rem;
  max-width: 90%;
}
.pending-approval-text {
  padding: 0.4rem 0.7rem;
  span {
    word-break: break-word;
  }
}
.list-width {
  .p-component {
    width: 100%;
  }
}
.ss-lv-sm-op {
  .copy-btn {
    color: $primaryColor;
    border: 0.07rem solid $primaryColor;
    padding: 0.4rem 0.4rem;
    border-radius: 0.2rem;
    cursor: pointer;
  }
  .overlay-text {
    width: 90% !important;
  }
  .share-link {
    font-size: 0.9rem;
  }
}
small {
  text-align: center;
}
.card {
  padding: unset;
}
@media screen and (max-width: 1100px) {
  h5 {
    font-size: 16px;
  }
  .fa-heart {
    font-size: 1.5rem;
  }
  .share-icon {
    font-size: 1.2rem;
  }
  .pi-phone {
    font-size: 0.2rem;
  }
  .for-text-list {
    font-size: 0.6rem;
  }
}
.list-info {
  p {
    margin: 0 0 0 0;
    line-height: unset;
    word-break: break-word;
  }
  p:first-letter {
    text-transform: capitalize;
  }
  h5 {
    margin: 0 0 0 0;
    line-height: unset;
    font-size: 16px;
  }
  h5:first-letter {
    text-transform: capitalize;
  }
}
</style>

<style lang="scss">
.ss-lv-sm-op {
  width: 40% !important;
}
.block-dialog {
  .p-dialog-content {
    padding-bottom: 0.5rem !important;
  }
}
</style>
