<template>
  <div class="p-grid">
    <div class="p-col-12 p-p-0">
      <grid-loading v-if="loading" :profile-number="profileNumber" />
      <DataView v-else :value="profiles" layout="grid">
        <template #grid="profile">
          <div class="p-col-12 p-sm-6 p-lg-4 p-xl-3">
            <div
              :class="`${
                profile.data.type === 'dashboard_user' ? '' : 'p-pb-0 mail-box'
              } card p-mb-0 p-mx-2 p-mt-3 p-p-0`"
            >
              <div class="grid">
                <div class="p-d-flex p-jc-center p-ai-center p-p-0">
                  <div
                    :class="`${
                      photoPresence(profile) ? '' : 'img-border'
                    } profile-grid`"
                  >
                    <img
                      :src="`
                        ${
                          !photoPresence(profile)
                            ? profile.data.attributes.gender === 'female' ||
                              profile.data.attributes.user_gender === 'female'
                              ? '/bride.png'
                              : '/groom.png'
                            : displayPhoto(profile)
                        }
                      `"
                      class="profile__img"
                      @click="blockMsg(profile)"
                    />
                    <img
                      :src="displayPhoto(profile)"
                      :class="photoPresence(profile) ? 'profile_bg_img' : ''"
                    />
                  </div>
                </div>
                <div
                  v-if="profile.data.type === 'dashboard_user'"
                  class="p-d-flex p-flex-column p-jc-between grid-info-sec"
                >
                  <h5 class="c-tc-pd pointer" @click="blockMsg(profile)">
                    {{ profile.data.attributes.first_name || 'NA' }}
                  </h5>
                  <small class="c-tc-p-cfor">
                    {{
                      ageHeight(
                        profile.data.attributes.age,
                        profile.data.attributes.height
                      )
                    }}
                  </small>
                  <p class="c-tc-p-cfor">
                    {{ profile.data.attributes.mother_tongue || 'NA' }}
                  </p>
                  <p class="c-tc-p-cfor">
                    {{
                      profile.data.attributes.address_state +
                      ', ' +
                      profile.data.attributes.address_country
                    }}
                  </p>
                  <p class="c-tc-p-cfor profile-data">
                    {{ splitUnderscore(profile.data.attributes.job_type) }}
                  </p>
                  <div
                    v-if="!authUser.is_admin"
                    class="p-d-flex p-jc-between p-align-center"
                    :class="
                      profile.data.attributes['sent_interest?'] ||
                      profile.data.attributes['received_interest?']
                        ? 'p-mt-1'
                        : null
                    "
                  >
                    <div
                      v-if="
                        profile.data.type === 'dashboard_user' && !blockPage
                      "
                    >
                      <i
                        v-tooltip.top="'Shortlist'"
                        :class="`${
                          profile.data.attributes['shortlisted?']
                            ? 'fas fa-heart'
                            : 'far fa-heart'
                        } heart-icon heart `"
                        @click="
                          updateList(
                            profile.data.attributes.id,
                            profile.data.attributes['shortlisted?']
                          ),
                            (profile.data.attributes['shortlisted?'] =
                              !profile.data.attributes['shortlisted?'])
                        "
                      ></i>
                      <i
                        v-if="profile.data.attributes.is_active"
                        id="link"
                        v-tooltip.top="'Share'"
                        class="pi pi-share-alt share-icon share"
                        :value="profile.data.attributes.id"
                        @click="toggle($event, profile.data.attributes.id)"
                      ></i>
                      <OverlayPanel ref="share_id" class="ss-gv-sm-op">
                        <div v-if="shareLink && shareLink !== 'error'">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            <div class="overlay-text">
                              <p
                                class="p-text-nowrap p-text-truncate share-link"
                              >
                                {{ shareLink }}
                              </p>
                            </div>
                            <div>
                              <i
                                class="copy-btn fas fa-copy"
                                @click="copyToClipboard"
                              />
                            </div>
                          </div>
                        </div>
                        <div v-else-if="shareLink === ''">
                          <div class="p-d-flex p-jc-between p-ai-center">
                            <dot-loader
                              class="dot-loader p-my-3"
                              theme="dark"
                            />
                          </div>
                        </div>
                      </OverlayPanel>
                    </div>
                    <div
                      v-if="
                        profile.data.type === 'dashboard_user' && !blockPage
                      "
                    >
                      <div
                        v-if="!profile.data.attributes.is_active"
                        class="p-d-flex p-ai-center deleted-profile"
                      >
                        <i class="pi pi-trash p-mr-1"></i>
                        <p>Profile deleted</p>
                      </div>
                      <div
                        v-else-if="profile.data.attributes['sent_interest?']"
                        class="p-d-flex p-ai-center interest-sent"
                      >
                        <i class="pi pi-check-circle p-mr-1"></i>
                        <p>interest sent</p>
                      </div>
                      <div
                        v-else-if="
                          profile.data.attributes['received_interest?']
                        "
                        class="p-d-flex p-ai-center interest-sent"
                      >
                        <i class="pi pi-check-circle p-mr-1"></i>
                        <p>interest received</p>
                      </div>
                      <div v-else>
                        <Button
                          label="Send interest"
                          class="p-button-sm send-interest"
                          @click="
                            sendinterest(profile.data.attributes.id),
                              (profile.data.attributes['sent_interest?'] = true)
                          "
                        />
                      </div>
                    </div>
                    <div
                      v-if="blockPage"
                      :class="
                        profile.data.attributes.is_active ? '' : 'bck-width'
                      "
                    >
                      <slot :profile="profile" />
                    </div>
                  </div>
                </div>
                <div
                  v-else
                  class="p-d-flex p-flex-column p-jc-between grid-info-sec"
                >
                  <div class="p-d-flex p-jc-between">
                    <h5
                      class="c-tc-pd p-mr-1 pointer"
                      @click="redirect(profile.data)"
                    >
                      {{
                        profile.data.attributes.user_name.split(' ')[0] +
                          ' ' +
                          profile.data.attributes.user_name.split(' ')[1] ||
                        'NA'
                      }}
                    </h5>
                    <div
                      v-if="profile.data.type !== 'received_interest'"
                      class="p-d-flex p-ai-center"
                    >
                      <i
                        v-tooltip.left="'Undo the sent interest?'"
                        class="pi pi-times close-button"
                        @click="confirmBlockUser(profile.data.id)"
                      ></i>
                    </div>
                  </div>
                  <p class="c-tc-p-cfor">
                    {{
                      ageHeight(
                        profile.data.attributes.user_age,
                        profile.data.attributes.user_height
                      )
                    }}
                  </p>
                  <p class="c-tc-p-cfor">
                    {{ profile.data.attributes.user_mother_tongue || 'NA' }}
                  </p>
                  <p class="c-tc-p-cfor">
                    {{
                      profile.data.attributes.user_state +
                      ', ' +
                      profile.data.attributes.user_country
                    }}
                  </p>
                  <p class="c-tc-p-cfor padding">
                    {{ splitUnderscore(profile.data.attributes.user_job) }}
                  </p>
                  <slot :profile="profile" />
                </div>
              </div>
            </div>
          </div>
        </template>
      </DataView>
      <div v-show="loadProfiles" class="loading-profiles">
        <div class="message">
          <div class="loadingio-spinner-dual-ring-5twzi63sc83">
            <div class="ldio-qt7gh4kx5nf">
              <div></div>
              <div><div></div></div>
            </div>
          </div>
          <p>Loading more profiles for you...</p>
        </div>
      </div>
    </div>
    <Dialog
      v-model:visible="blockDialog"
      :modal="true"
      :draggable="false"
      :closable="true"
      :close-on-escape="true"
      header="Confirmation"
      :dismissable-mask="true"
      class="block-dialog"
    >
      <div class="p-d-flex p-flex-column block-user-dialog">
        <p>Do you want to undo the sent interest?</p>
        <div class="p-d-flex p-ai-center">
          <Checkbox id="block_user_check" v-model="block" :binary="true" />
          <label for="block_user_check" class="p-ml-2">Block as well</label>
        </div>
      </div>
      <template #footer>
        <Button
          label="Yes"
          class="p-mr-5 p-button-outlined"
          @click="blockUser()"
        /><Button label="No" autofocus @click="blockDialog = false" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import DotLoader from '@/utilities/DotLoader.vue';
import '@/assets/ListAndGridView.scss';
import GridLoading from './GridLoading.vue';
import { profileApprovalMixin } from '@/mixins/profileApprovalMixin.js';
import { viewMixin } from '@/mixins/gridAndListViewMixin.js';
import { formFieldMixin } from '@/mixins/formFieldMixin.js';
import { ageHeightMixin } from '@/mixins/ageHeightMixin.js';
export default {
  components: { GridLoading, DotLoader },
  mixins: [viewMixin, formFieldMixin, profileApprovalMixin, ageHeightMixin],
  props: {
    profiles: {
      type: Array,
      default() {
        return [];
      },
    },
    profileNumber: {
      type: Number,
      default() {
        return 10;
      },
    },
    loading: { type: Boolean, default: true },
    loadProfiles: Boolean,
    showPendingApproval: { type: Boolean, default: false },
    blockPage: { type: Boolean, default: false },
  },
  data() {
    return {
      blockDialog: false,
      selectedUserId: '',
      block: false,
    };
  },
};
</script>

<style scoped lang="scss">
.block-user-dialog {
  width: 35rem;
  max-width: 90%;
}
.bck-width {
  width: 100%;
}
.grid {
  margin: 0.3rem;
  display: flex;
  justify-content: flex-start !important;
  position: relative;
}
.padding {
  padding-bottom: 5px;
}

.icon-align {
  padding-top: 6px;
  align-items: center;
}
.heart {
  font-size: 1.2rem;
  padding-right: 1vw;
}
@media only screen and (min-width: 1200px) and (max-width: 1250px) {
  .heart {
    padding-right: 3px;
  }
}
.share {
  font-size: 1rem;
}
.ss-gv-sm-op {
  .copy-btn {
    color: $primaryColor;
    border: 0.07rem solid $primaryColor;
    padding: 0.4rem 0.4rem;
    border-radius: 0.2rem;
    cursor: pointer;
  }
  .overlay-text {
    width: 90% !important;
  }
  .share-link {
    font-size: 0.9rem;
  }
}
.grid-info-sec {
  min-height: 100px;
  padding: 0px 0px 2px 8px;
  word-break: break-word;
  overflow: hidden;
  position: relative;
  width: 100%;

  h5 {
    margin: 0;
    line-height: unset;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: $inputTextColor;
    padding-top: 5px;
  }
  button {
    font-size: 12px !important;
  }
  p {
    margin: 0;
    line-height: unset;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  small {
    margin: 0;
    line-height: unset;
    font-size: 12px;
    color: $labelColor;
  }
  p:first-letter {
    text-transform: capitalize;
  }
  h5:first-letter {
    text-transform: capitalize;
  }
}
.send-interest {
  span .p-button-icon-right {
    margin-left: 0.5rem;
  }
}
</style>
<style lang="scss">
.ss-gv-sm-op {
  width: 30% !important;
}
.block-dialog {
  .p-dialog-content {
    padding-bottom: 0.5rem !important;
  }
}
.send-interest .p-button-icon-right {
  margin-left: 0.1rem;
}
.block-user-dialog {
  .p-dialog-content {
    position: relative;
    padding: 2.5rem;
    width: 470px;
    max-width: 90vw;
  }
  .close-block-user-dialog {
    position: absolute;
    top: 15px;
    right: 15px;
    height: 25px;
    width: 25px;
    text-align: center;
    cursor: pointer;
    border: 1px solid $primaryColor;
    border-radius: 50%;
    color: $primaryColor;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .block-user-content {
    display: flex;
    flex-direction: column;
    .confirmation-text {
      display: flex;
      justify-content: center;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 26px;
      letter-spacing: -0.015em;
      text-align: center;
      color: #737373;
      margin-bottom: 0.7rem;
    }
    .confirmation-buttons {
      display: flex;
      align-items: center;
      justify-content: center;
      .confirmation-button {
        width: 6.25em;
        margin-left: 1rem;
      }
    }
  }
}
</style>
