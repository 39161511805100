export const ageHeightMixin = {
  methods: {
    ageHeight(age, height) {
      return (
        age +
        'yrs, ' +
        Math.floor(height) +
        'ft ' +
        Math.round((height % 1) * 10) +
        'in'
      );
    },
  },
};
