<template>
  <div :class="theme === 'dark' ? 'typing_loader_dark' : 'typing_loader'" />
</template>

<script>
export default {
  name: 'DotLoader',
  props: ['theme'],
};
</script>

<style scoped lang="scss">
/*circle classick*/
/*Typing Loader*/
.typing_loader {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -webkit-animation: typing 1s linear infinite alternate;
  -moz-animation: Typing 1s linear infinite alternate;
  animation: typing 1s linear infinite alternate;
  margin: 20px auto; /* Not necessary- its only for layouting*/
  position: relative;
  left: -12px;
}
@-webkit-keyframes typing {
  0% {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  25% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 2),
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  75% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px rgba(255, 255, 255, 1);
  }
}

@-moz-keyframes typing {
  0% {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  25% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 2),
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  75% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px rgba(255, 255, 255, 1);
  }
}

@keyframes typing {
  0% {
    background-color: rgba(255, 255, 255, 1);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  25% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 2),
      24px 0px 0px 0px rgba(255, 255, 255, 0.2);
  }
  75% {
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(255, 255, 255, 0.2),
      24px 0px 0px 0px rgba(255, 255, 255, 1);
  }
}
.typing_loader_dark {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  -webkit-animation: typing_dark 1s linear infinite alternate;
  -moz-animation: typing_dark 1s linear infinite alternate;
  animation: typing_dark 1s linear infinite alternate;
  margin: 20px auto; /* Not necessary- its only for layouting*/
  position: relative;
  left: -12px;
}
@-webkit-keyframes typing_dark {
  0% {
    background-color: #333;
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2), 24px 0px 0px 0px #333;
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2), 24px 0px 0px 0px #333;
  }
}

@-moz-keyframes typing_dark {
  0% {
    background-color: #333;
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.2);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2), 24px 0px 0px 0px #333;
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2), 24px 0px 0px 0px #333;
  }
}

@keyframes typing_dark {
  0% {
    background-color: #333;
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2),
      24px 0px 0px 0px rgba(0, 0, 0, 0.4);
  }
  25% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2), 24px 0px 0px 0px #333;
  }
  75% {
    background-color: rgba(0, 0, 0, 0.4);
    box-shadow: 12px 0px 0px 0px rgba(0, 0, 0, 0.2), 24px 0px 0px 0px #333;
  }
}
</style>
